@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.where-to-buy {
  padding: rfs-value(45px 15px);

  @include media-breakpoint-up(md) {
    padding: rfs-value(60px 30px);
  }

  h2 {
    text-align: left;
  }
  .title {
    font-size: rfs-value(30px);
    line-height: 1.2;
    text-align: center;
    margin-bottom: rfs-value(12px);

    @include media-breakpoint-up(md) {
      font-size: rfs-value(36px);
    }
  }

  .mx_title {
    @extend .title; // Extend properties from .title class
    text-align: left; // Override text-align to left
  }

  .tabs {
    justify-content: center;
    border: none;
  }
  .mt_2 {
    margin-top: rfs-value(2rem);
  }
  .mb_1 {
    margin-bottom: rfs-value(1rem);
  }
  .mb_3 {
    margin-bottom: rfs-value(3rem);
  }
  .mx_ul {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 1rem;
    padding-left: 2rem;
    list-style-type: inherit;
  }
  .mx_ul li {
    margin-bottom: rfs-value(0.3rem);
  }

  :global(.nav-item) {
    padding: rfs-value(15px);
    line-height: rfs-value(18px);
  }
  :global(.nav-link) {
    border: none;
    border-bottom: 2px solid transparent;
    color: #404040;
    display: inline-block;
    font-size: rfs-value(12px);
    font-weight: 700;
    letter-spacing: rfs-value(1.2px);
    padding: rfs-value(0 0 1px);
    text-transform: uppercase;
    transition: border-color 0.2s ease-out;

    &:hover,
    &:global(.active) {
      border-color: $nikon-yellow;
      color: #404040;
    }
  }
  svg {
    display: inline-block;
  }
}
